.tags {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    letter-spacing: 0.28px;
    font-size: "14px";
    width: "fit-content";
}

.tags-red {
    border: 1px solid #352525;
    background: rgba(255, 0, 0, 0.05);
    color: #FFD3D3;
}

.tags-green {
    border: 1px solid #374139;
    background: rgba(238, 255, 0, 0.05);
    color: #DEFFE4;
}

.tags-blue {
    border: 1px solid rgba(81, 86, 96, 0.39);
    background: rgba(90, 94, 157, 0.11);
    color: #E3EDFF;
}

.tags-pink {
    border: 1px solid rgba(234, 221, 255, 0.11);
    background: rgba(255, 0, 208, 0.05);
    color: #EADDFF;
}